var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('DataViewer',{attrs:{"title":_vm.$t('agent_information'),"data":_vm.Agent}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card"},[_c('b-button',{attrs:{"variant":'danger'},on:{"click":function($event){return _vm.deletee()}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card"},[_c('b-button',{attrs:{"variant":'warning'},on:{"click":function($event){return _vm.editshow()}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('b-modal',{attrs:{"no-close-on-backdrop":"","id":"modal-edit","title":_vm.$t('edit_agent'),"centered":"","scrollable":"","title-class":"font-18"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div')]},proxy:true}])},[_c('form',{ref:"modal-edit",on:{"submit":function($event){$event.preventDefault();return _vm.edit()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.name),expression:"editData.name"}],staticClass:"form-control mb-4",attrs:{"type":"text","placeholder":_vm.$t('name'),"required":""},domProps:{"value":(_vm.editData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "name", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.phone),expression:"editData.phone"}],staticClass:"form-control mb-4",class:{
                'is-invalid': _vm.$v.editData.phone.$error,
              },attrs:{"type":"tel","placeholder":_vm.$t('phone'),"required":""},domProps:{"value":(_vm.editData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "phone", $event.target.value)}}}),(!_vm.$v.editData.phone.phone)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("please Enter Valid 11 digit Phone number")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.email),expression:"editData.email"}],staticClass:"form-control mb-4",class:{
                'is-invalid': _vm.$v.editData.email.$error,
              },attrs:{"type":"email","placeholder":_vm.$t('email'),"required":""},domProps:{"value":(_vm.editData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "email", $event.target.value)}}}),_c('b-form-group',{attrs:{"label":"Role"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":0},model:{value:(_vm.editData.role),callback:function ($$v) {_vm.$set(_vm.editData, "role", $$v)},expression:"editData.role"}},[_vm._v("Admin")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":1},model:{value:(_vm.editData.role),callback:function ($$v) {_vm.$set(_vm.editData, "role", $$v)},expression:"editData.role"}},[_vm._v("Agent")])]}}])}),(_vm.editData.role === 1)?_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t("agent_roles"))+": ")]):_vm._e(),(_vm.editData.role === 1)?_c('multiselect',{staticClass:"mb-3",class:{
                'is-invalid': _vm.$v.editData.privileges.$error,
              },attrs:{"options":_vm.options,"show-labels":false,"placeholder":_vm.$t('agent_roles'),"multiple":true,"showLabels":false,"hideSelected":true,"custom-label":_vm.getRuleName,"max-height":150,"dir":_vm.current_language === 'ar'? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noResults'))+" ")]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noOptions'))+" ")]},proxy:true}],null,false,725877946),model:{value:(_vm.editData.privileges),callback:function ($$v) {_vm.$set(_vm.editData, "privileges", $$v)},expression:"editData.privileges"}}):_vm._e(),(_vm.$v.editData.privileges.$error)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('pleaseSelect')))]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}}),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{on:{"click":function($event){_vm.$bvModal.hide('modal-edit');_vm.loadAgent();}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("ok")))])],1)],1)],1)])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }