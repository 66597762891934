<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "@/components/elements/DataViewer.vue";
import {
  GetAgentById,
  UpdateAgent,
  DeleteAgent,
} from "../../../../services/agents";
import { required, email, numeric } from "vuelidate/lib/validators";
import swal from "sweetalert2";
import Multiselect from 'vue-multiselect'
/**
 * Typography component
 */

export default {
  page: {
    title: "Agent",
    meta: [{ name: "Agent", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader,Multiselect },
  data() {
    return {
      title: this.$t("agent"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.agents.text"),
          href: "/agent/all",
        },
        {
          text: this.$t("agent_information"),

          active: true,
        },
      ],
      current_language: localStorage.getItem("language") || 'en',
      data: {},
      options:["dashboard","agent","client","question","contract","consultant","contract_type"],
      editData: {
        name: "",
        phone: "",
        email: "",
        privileges: [],
        role: 1,
      },
    };
  },
  created() {
    this.loadAgent();
  },
  computed: {
    Agent() {
      return [
        { key: this.$t("name"), value: this.data.name },
        { key: this.$t("phone"), value: this.data.phone },
        { key: this.$t("email"), value: this.data.email },

        {
          key: this.$t("role"),
          value: this.data.role === 0 ? "Admin" : "Agent",
        },
        {
          key: this.$t("privileges"),
          value: this.data?.privileges?.map((item) => {
            // Modify the item based on a condition
            if (item === "dashboard") {
              // Modify the item's value here
              item = this.$t("menuitems.dashboard.text");
            } else if (item === "agent") {
              item = this.$t("menuitems.agents.text");
            } else if (item === "client") {
              item = this.$t("menuitems.clients.text");
            } else if (item === "consultant") {
              item = this.$t("menuitems.consultant.text");
            } else if (item === "contract") {
              item = this.$t("menuitems.contracts.text");
            } else if (item === "question") {
              item = this.$t("menuitems.questions.text");
            } else if (item === "payment") {
              item = this.$t("menuitems.payments.text");
            } else if (item === "contract_type") {
              item = this.$t("menuitems.contract_types.text");
            }
            return item;
          }),
          tags: true,
        },
      ];
    },
  },
  validations: {
    editData: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        numeric,
        phone: (value) => {
          // Validate that the phone number has exactly 11 numeric digits
          return /^[0-9]{11}$/.test(value);
        },
      },

      privileges: {
        required,
      },
      role: {
        required,
      },
    },
  },
  methods: {
    loadAgent() {
      GetAgentById(this.$route.params.id).then((data) => {
        // console.log(data);
        this.data = data;
      });
    },
    edit() {
      // this.$bvModal.show("modal-add");
      this.$v.editData.$touch();

      if (this.$v.editData.$invalid) {
        return;
      } else {
        // console.log(this.editData);
        UpdateAgent(this.$route.params.id, this.editData).then(() => {
          // console.log(data);
          this.loadAgent();
          this.$bvModal.hide("modal-edit");
          this.successmsg(this.$t("data_has_been_updated_success"));
        });
      }
    },
    deletee() {
      // this.$bvModal.show("modal-add");
      swal
        .fire({
          position: "center",
          icon: "warning",
          title: this.$t("are_you_sure_delete_agent"),
          showConfirmButton: true,
        })
        .then((res) => {
          if (res) {
            DeleteAgent(this.$route.params.id).then(() => {
              this.successmsg(this.$t("data_has_been_updated_success"));
              this.$router.push({ path: "/agent/all" });
            });
          }
        });
    },
    editshow() {
      this.editData = this.data;
      this.$bvModal.show("modal-edit");
    },
    successmsg(msg) {
      swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    getRuleName(val){
      const optionsTitles ={
        dashboard:this.$t("menuitems.dashboard.text"),
        agent:this.$t("menuitems.agents.text"),
        client:this.$t("menuitems.clients.text"),
        question:this.$t("menuitems.questions.text"),
        contract:this.$t("menuitems.contracts.text"),
        consultant:this.$t("menuitems.consultant.text"),
        contract_type:this.$t("menuitems.contract_types.text"),
      }
      return optionsTitles[val] || this.$t('unknown')
    },
  },
  watch: {
    "editData.role"(newValue) {
      if (newValue === 0) {
        // Perform your actions when myAttribute changes to 0
        this.editData.privileges = [
          "dashboard",
          "agent",
          "client",
          "question",
          "contract",
          "consultant",
          "contract_type",
        ];
      } else {
        this.editData.privileges = [];
      }
    },
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <DataViewer :title="$t('agent_information')" :data="Agent" />
    <div class="row">
      <div class="col-6">
        <div class="card">
          <b-button @click="deletee()" :variant="'danger'">
            {{ $t("delete") }}
          </b-button>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <b-button @click="editshow()" :variant="'warning'">
            {{ $t("edit") }}
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-edit"
            :title="$t('edit_agent')"
            centered
            scrollable
            title-class="font-18"
          >
            <!-- <template #modal-ok> {{ $t("ok") }} </template>
      <template #modal-cancel> {{ $t("cancel") }} </template> -->
            <template #modal-footer>
              <div></div>
            </template>
            <form @submit.prevent="edit()" ref="modal-edit">
              <input
                type="text"
                :placeholder="$t('name')"
                v-model="editData.name"
                class="form-control mb-4"
                required
              />
              <input
                type="tel"
                :placeholder="$t('phone')"
                v-model="editData.phone"
                class="form-control mb-4"
                required
                :class="{
                  'is-invalid': $v.editData.phone.$error,
                }"
              />
              <span class="invalid-feedback" v-if="!$v.editData.phone.phone"
                >please Enter Valid 11 digit Phone number</span
              >
              <input
                type="email"
                :placeholder="$t('email')"
                v-model="editData.email"
                class="form-control mb-4"
                required
                :class="{
                  'is-invalid': $v.editData.email.$error,
                }"
              />

              <b-form-group label="Role" v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="editData.role"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="0"
                  >Admin</b-form-radio
                >
                <b-form-radio
                  v-model="editData.role"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="1"
                  >Agent</b-form-radio
                >
              </b-form-group>
              <div v-if="editData.role === 1" class="mt-3">
                {{ $t("agent_roles") }}:
              </div>
              <multiselect v-model="editData.privileges"
                           v-if="editData.role === 1"
                           :options="options"
                           :show-labels="false"
                           :placeholder="$t('agent_roles')"
                           :multiple="true"
                           :showLabels="false"
                           :hideSelected="true"
                           :custom-label="getRuleName"
                           :max-height="150"
                           :dir="current_language === 'ar'? 'rtl' : 'ltr'"
                           class="mb-3"
                           :class="{
                  'is-invalid': $v.editData.privileges.$error,
                }"
              >
                <template #noResult>
                  {{ $t('noResults') }}
                </template>
                <template #noOptions>
                  {{ $t('noOptions') }}
                </template>
              </multiselect>

              <span
                class="invalid-feedback"
                v-if="$v.editData.privileges.$error"
                >{{ $t('pleaseSelect') }}</span
              >
              <b-row
                ><b-col cols="8"></b-col>
                <b-col cols="2"
                  ><b-button @click="$bvModal.hide('modal-edit');loadAgent();">{{
                    $t("cancel")
                  }}</b-button>
                </b-col>
                <b-col cols="2">
                  <b-button variant="primary" type="submit">{{
                    $t("ok")
                  }}</b-button></b-col
                ></b-row
              >
            </form>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
